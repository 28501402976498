<template>
  <div v-if="isResetPassword" class="row card-container ">
    <div class="col">
      <form class="form card card-body responsive">
        <h1>パスワードリセット</h1>
        <div class="form-group mx-auto">
          <label class="inputEmail" for="inputEmail">メールアドレス</label>
          <input id="inputEmail" v-model="email" class="form-control" required type="email">
          <div v-show="errors['email']" id="err_inputEmail" class="form-error">{{ errors['email'] }}</div>
        </div>
        <p class="mt-3 mb-0 mx-auto">※正しく届かない場合がありますのでhealthcheck-healo@jta-tennis.or.jpからのメールの受信を許可してください。
        </p>

        <div class="btn btn-primary ok font-weight-bold mx-auto m-t-20" v-on:click="sendMail">送信
        </div>

        <router-link :to="{ name: 'Login' }"
                     class="text-center m-t-20 forgot-password ashen-link light text-decoration-none">キャンセル
        </router-link>

      </form>
    </div>
  </div>
  <div v-else class="row second-row card-container">
    <div class="col">
      <form class="form card card-body responsive">
        <h1 class="font-20 mb-0">メールの送信が完了しました</h1>
        <div class="form-group m-b-5">
          <p class="text-left color-33">パスワードリセットに必要なメールを送信しました。
            メール記載のリンクからパスワードの再設定に進んでください。
          </p>
        </div>
        <div id="okButton" class="btn btn-primary font-weight-bold mx-auto" v-on:click="login">ログインに戻る
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Common from 'jsadminclient/common'
import {SetAdminResetPassword} from 'jsadminclient/index'

export default {
  name: "ResetPassword",
  data() {
    return {
      email: '',
      isResetPassword: true,
      errors: []
    }
  },
  methods: {
    login() {
      // TODO: check cookie
      this.$router.push({name: 'Login'});
    },
    sendMail() {
      this.errors = [];

      const api = Common.createLoginApi()

      const setResetPasswordFinish = new SetAdminResetPassword()
      setResetPasswordFinish.email = this.email
      api.loginResetPasswordPostAsync(setResetPasswordFinish)
          .then(() => {
            this.isResetPassword = false
          })
          .catch((error) => {
            Common.getErrorList(error.response, this.errors)
          })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
